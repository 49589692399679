@import '../../../../styles/customMediaQueries.css';

.locationAddress {
  flex-grow: 1;
  text-align: left;
  & .locationAutocompleteInputIcon {
    display: none;
  }
  & > div {
    & > input {
      min-height: auto;
      box-shadow: none;
      color: var(--textColor);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      background-color: var(--colorWhite);
      border: none;
      margin: 0;
      padding: 0;
      @media (--viewportMedium) {
        min-height: 50px;
      }

      &::placeholder {
        opacity: 1;
        color: var(--textLightColor);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}
.heroSearchBar {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-color: var(--colorWhite);
  flex-direction: column;
  padding: 20px;
  @media (--viewportSmall) {
    padding: 0 30px;
    flex-direction: row;
  }
  & .findAbout {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    @media (--viewportSmall) {
      padding-right: 24px;
      justify-content: flex-start;
      width: auto;
      min-width: 190px;
    }
    & > svg {
      margin-right: 12px;
      transform: rotate(270deg);
      stroke: var(--colorBlack);
      width: 16px;
      height: 16px;
    }
  }
  & .heroSearch {
    flex-grow: 1;
    padding: 0px 10px;
    width: 100%;
    border: solid 1px var(--colorGrey100);
    margin: 10px 0;
    @media (--viewportSmall) {
      padding: 16px 10px;
      margin: 0;
      border: none;
      border-left: solid 1px var(--colorGrey100);
      width: auto;
    }
    & > div {
      display: flex;
      & > div {
        width: 100%;
        text-align: left;
        border: none !important;
        box-shadow: none;
        border-radius: 0;
        cursor: pointer;
        & input {
          border: none;
          box-shadow: none;
          cursor: pointer;
        }
      }
    }
  }
  & .submitButton {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
    border: solid 1px var(--marketplaceColor);
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: var(--transitionStyle);
    width: 100%;
    @media (--viewportSmall) {
      width: auto;
    }
    &:hover {
      transition: var(--transitionStyle);
      background-color: var(--marketplaceColorDark);
      border: solid 1px var(--marketplaceColorDark);
      color: var(--colorWhite);
    }
  }
}
.landingLocationSearch {
  display: flex;
  align-items: center;
  flex-grow: 1;
  position: relative;
  width: 100%;
  @media (--viewportLarge) {
    margin-right: 12px;
    width: auto;
  }
}
.searchBtn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  border-radius: 100px;
  text-align: center;
  background-color: var(--marketplaceColor);
  border: none;
  transition: var(--transitionStyle);
  color: var(--colorWhite);
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 20px;
  width: 50px;
  height: 50px;
  padding: 12px;
  @media (--viewportMedium) {
    width: auto;
    height: auto;
    font-size: 16px;
    line-height: 20px;
    padding: 12px 30px;
  }
  &:hover {
    background-color: var(--marketplaceColorDark);
    color: var(--colorWhite);
    border: none;
  }
  & > svg {
    @media (--viewportMedium) {
      margin-right: 10px;
    }
    & path {
      fill: var(--colorWhite);
    }
  }
}
.guestsWrapper {
  position: relative;
  margin-top: 12px;
  width: 100%;
  @media (--viewportLarge) {
    margin-right: 12px;
    margin-top: 0px;
    width: auto;
  }
  & .guestsBtn {
    background-color: var(--colorWhite);
    border-radius: 7px;
    border: none;
    padding: 10px 10px;
    height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: var(--fontWeightRegular);
    font-size: 14px;
    line-height: 20px;
    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 20px;
      padding: 14px 16px 14px 12px;
    }
    & > svg {
      margin-right: 8px;
      width: 18px;
      @media (--viewportMedium) {
        width: 20px;
      }
    }
  }
}
.guestsDropdown {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 9px;
  width: 100%;
  background-color: var(--colorWhite);
  min-width: 260px;
  z-index: 1;
  border: 1px solid #d8dce1;
  box-shadow: var(--boxShadowFilterButton);
  text-align: left;
  border-radius: 4px;
  padding: 20px;
  & .choosePassangers {
    margin-bottom: 20px;
    & .passangerType {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      & .passangerCount {
        display: flex;
        align-items: center;
        & > * {
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
        }
        & .totalPersons {
          font-weight: var(--fontWeightSemiBold);
          color: var(--colorBlack);
          font-size: 15px;
          line-height: 100%;
        }
        & .personCategory {
          font-weight: var(--fontWeightRegular);
          color: var(--colorBlack);
          font-size: 15px;
          line-height: 100%;
        }
      }
      & .selectBox {
        display: inline-flex;
        align-items: center;
        &.checkBox {
          & > span {
            padding: 0;
            & > label {
              margin: 0;
              padding-right: 5px;
              & > span {
                margin-right: 0px;
              }
            }
          }
        }
        & > * {
          margin-right: 5px;
          padding: 0px 8px;
          color: var(--marketplaceColor);
          font-weight: 600;
          &:last-child {
            margin-right: 0;
          }
        }
        & > button {
          width: 30px;
          height: 30px;
          border-radius: 100px;
          cursor: pointer;
          border: solid 1px var(--marketplaceColor);
          background-color: var(--colorWhite);
          color: var(--marketplaceColor);
          transition: var(--transitionStyle);
          &:hover {
            background-color: var(--marketplaceColorDark);
            border: solid 1px var(--marketplaceColorDark);
            color: var(--colorWhite);
            transition: var(--transitionStyle);
          }
        }
      }
    }
  }
  & .applyBtn {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
    border: none;
    border-radius: 5px;
    font-size: 14px;
    padding: 7px 20px;
    font-weight: var(--fontWeightSemiBold);
    transition: var(--transitionStyle);
    float: right;
    cursor: pointer;
    &:hover {
      background-color: var(--marketplaceColorDark);
      color: var(--colorWhite);
    }
  }
}
.travelDateSection {
  width: 100%;
  max-width: 100%;
  margin-top: 12px;
  @media (--viewportLarge) {
    max-width: 40%;
    margin-top: 0px;
  }
}
.searchPageFilter {
  & .landingLocationSearch {
    place-content: center flex-start;
    align-items: center;
    background-color: rgb(243, 243, 242);
    border-radius: 12px;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row nowrap;
    gap: 0px;
    height: 48px;
    overflow: visible;
    padding: 12px 16px;
    position: relative;
    width: 100%;
    @media (--viewportLarge) {
      width: 240px;
    }
    & > input {
      background-color: transparent;
      border: none;
      box-shadow: none;
      padding: 0;
    }
    & > div {
      &:first-child {
        display: none;
      }
    }
  }
  & .travelDateSection {
    & div {
      &::before {
        opacity: 0.5;
      }
      & input {
        place-content: center flex-start;
        align-items: center;
        background-color: rgb(243, 243, 242);
        border-radius: 12px;
        display: flex;
        flex: 0 0 auto;
        flex-flow: row nowrap;
        gap: 0px;
        height: 48px;
        overflow: visible;
        padding: 12px 16px 12px 42px;
        position: relative;
      }
    }
  }
  & .guestsWrapper {
    & .guestsBtn {
      place-content: center flex-start;
      align-items: center;
      background-color: rgb(243, 243, 242);
      border-radius: 12px;
      display: flex;
      flex: 0 0 auto;
      flex-flow: row nowrap;
      gap: 0px;
      height: 48px;
      overflow: visible;
      padding: 12px 16px;
      position: relative;
      & > svg {
        margin-right: 10px;
        opacity: 0.5;
      }
    }
  }
  & .searchIcon {
    align-self: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--marketplaceColor);
    border: solid 1px var(--marketplaceColor);
    transition: var(--transitionStyle);
    padding: 10px 20px;
    margin-top: 24px;
    @media (--viewportLarge) {
      width: 46px;
      height: 46px;
      margin-top: 0px;
      min-height: auto;
      border-radius: 100px;
      padding: 0;
    }
    &:hover {
      background-color: var(--marketplaceColorDark);
      border-color: var(--marketplaceColorDark);
      & > svg {
        transition: var(--transitionStyle);
        & circle {
          transition: var(--transitionStyle);
          stroke: var(--colorWhite);
        }
        & path {
          transition: var(--transitionStyle);
          stroke: var(--colorWhite);
        }
      }
    }
    & > svg {
      transition: var(--transitionStyle);
      width: 18px;
      height: 18px;
      transform: rotate(266deg);
      & circle {
        transition: var(--transitionStyle);
        stroke: var(--colorWhite);
      }
      & path {
        transition: var(--transitionStyle);
        stroke: var(--colorWhite);
      }
    }
  }
}

.footerBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .clearFilter {
    height: 50px;
  }
  & > button {
    width: auto;
    line-height: 120%;
    height: 50px;
  }
}

.clearFilter {
  margin: 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--colorWhite);
  border: solid 1px var(--colorGrey100);
  color: var(--colorBlack);
  line-height: 130%;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 24px;
  text-align: center;
  @media (--viewportLarge) {
    margin-top: 0px;
    margin-right: 10px;
    border-radius: 100px;
    line-height: 100%;
    font-size: 30px;
    padding: 0;
    width: 46px;
    height: 46px;
    border: none;
    background-color: rgb(243, 243, 242);
  }
  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
    text-decoration: none;
  }
}

.descriptionContainer {
  margin-top: 10px;
  font-size: 11px;
  font-weight: 700;
  color: #a49d9f;
}

.petsDescription {
  font-size: 11px;
  font-weight: 700;
  line-height: 12px;
  margin-top: 6px;
}
