@import '../../../styles/customMediaQueries.css';

.heroContainer {
  background-image: url('../../../assets/bg.jpg');
  background-position: center right;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 24px 50px;
  position: relative;
  min-height: 60vh;
  display: flex;
  align-items: center;
  position: relative;
  @media (--viewportMedium) {
    padding: 50px 24px 100px;
    min-height: 80vh;
    background-position: center;
  }
  &::before {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: var(--colorBlack);
    content: '';
    left: 0;
    top: 0;
    opacity: 0.5;
  }
  & .contentWidth {
    position: relative;
    z-index: 1;
    max-width: 955px;
    width: 100%;
    margin: 0px auto;
    & .heroContent {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      & > h1 {
        color: var(--colorWhite);
        font-size: 28px;
        line-height: 120%;
        font-weight: 600;
        letter-spacing: 0em;
        margin: 0px 0 10px 0;
        padding: 0;
        @media (--viewportSmall) {
          font-size: 35px;
        }
        @media (--viewportMedium) {
          font-size: 40px;
        }
        @media (--viewportLarge) {
          font-size: 50px;
        }
        @media (--viewportLargeWithPaddings) {
          font-size: 60px;
        }
      }
      & p {
        color: var(--colorWhite);
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0em;
        text-align: center;
        max-width: 80%;
        width: 100%;
        @media (--viewportMedium) {
          margin: 10px 0 0 0;
          font-size: 18px;
        }
        @media (--viewportLargeWithPaddings) {
          margin: 17px 0 0 0;
          font-size: 20px;
        }
      }
      & .bottomContent {
        margin-top: 10px;
        @media (--viewportMedium) {
          margin-top: 0px;
        }
        & > p {
          position: relative;
          &::after {
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
            width: 36px;
            height: 1px;
            display: inline-block;
            background-color: var(--colorGrey100);
            content: '';
          }
        }
      }
      & .keywords {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 16px;
        @media (--viewportLargeWithPaddings) {
          margin-top: 26px;
        }
        & > span {
          &.keyword {
            &:hover {
              color: var(--colorWhite);
            }
          }
        }
        & .keyword {
          text-decoration: none;
          font-size: 15px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0em;
          color: var(--colorWhite);
          margin-bottom: 8px;
          transition: var(--transitionStyle);

          @media (--viewportSmall) {
            margin-bottom: 10px;
          }
          @media (--viewportLargeWithPaddings) {
            margin-bottom: 15px;
          }
          &:not(:last-child) {
            margin-right: 10px;
            @media (--viewportSmall) {
              margin-right: 14px;
            }
            @media (--viewportMedium) {
              margin-right: 24px;
            }
          }
          &:hover {
            transition: var(--transitionStyle);
            color: var(--marketplaceColor);
          }
        }
      }
      & .heroSearchSec {
        width: 100%;
        max-width: 800px;
        margin: 20px auto;

        @media (--viewportMedium) {
          padding: 0 32px;
          margin: 34px auto 0;
        }
        @media (--viewportLargeWithPaddings) {
          margin: 48px auto 0;
        }
        & .landingSearchForm {
          display: flex;
          align-items: stretch;
          justify-content: space-between;
          width: 100%;
          max-width: 100%;
          margin: 0 auto;
          padding: 8px 8px 8px 25px;
          background-color: var(--colorWhite);
          border-radius: 100px;
          @media (--viewportMedium) {
            padding: 10px 10px 10px 25px;
          }
        }
      }
    }
  }
}

.title {
  composes: h1 from global;
}

.sectionSubHeading {
  composes: sectionSubHeading from global;
}
